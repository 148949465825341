$cityWidth: 4200px;

:root {
  --city-width: #{$cityWidth};

  @media (min-width: 800px) {
    --city-width: #{$cityWidth + 500px};
  }

  @media (min-width: 1700px) {
    --city-width: #{$cityWidth + 600px};
  }
}

.City {
  width: 100%;
  height: 100%;
  position: relative;
  background: linear-gradient(to bottom, #51a1e4, #e6f6fc);
  overflow: hidden hidden;

  .scrollbar-hidden {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
  }

  .scroll-x {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .city-width {
    bottom: 0;
    left: 0;
    height: 100%;
    position: absolute;
    width: var(--city-width);
    overflow-x: hidden;
    isolation: isolate;
  }

  .pavement-front,
  .road,
  .skyline,
  .trees,
  .sky {
    position: absolute;
    bottom: 0;
    width: calc(max(100%, var(--city-width)) * 2);
    pointer-events: none;
    left: -2000px;

    &.pavement-front {
      background: url("backgrounds/pavement-front.svg") repeat-x;
      height: 48px;
      z-index: 6;
    }

    &.road {
      bottom: 8px;
      height: 80px;
      background: url("backgrounds/road.svg") repeat-x;
    }

    &.skyline {
      bottom: 96px;
      height: 275px;
      background: url("backgrounds/skyline.svg") repeat-x;
    }

    &.sky {
      bottom: 96px;
      height: 475px;
      background: url("backgrounds/clouds.svg") repeat-x;
    }

    &.trees {
      bottom: 96px;
      height: 190px;
      background: url("backgrounds/buildings-and-trees.svg") repeat-x;
    }
  }

  .pavement-back {
    top: 0;
    left: -570px;
    height: 100%;
    width: calc(max(100%, var(--city-width)) * 2);
    position: absolute;
    overflow-x: hidden;
    overflow-y: hidden;

    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      background: url("backgrounds/pavement-back.svg") repeat-x;
      bottom: 88px;
      height: 30px;
    }
  }

  .lottie {
    position: absolute;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;

    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.25),
      rgba(255, 255, 255, 0.65)
    );
  }

  .door-anim {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    svg {
      width: auto !important;
    }
  }
}

@keyframes driving-car {
  0% {
    left: -200px;
  }
  100% {
    left: $cityWidth + 800px;
  }
}
